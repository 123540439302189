<template>
  <div>
    <template v-if="!productData">
      <h4 class="alert-heading">No se encontro el producto</h4>
      <div class="alert-body">
        Por favor, regresa a la
        <b-link
          class="alert-link"
          :to="{ name: 'store-products', params: { id: $route.params.id } }"
        >
          lista de productos
        </b-link>
        y selecciona un producto para agregar al inventario.
      </div>
    </template>

    <template v-else>
      <b-row>
        <b-col cols="12" md="6">
          <product-info-card :product="productData" />
        </b-col>
        <b-col>
          <b-card>
            <h3 class="mb-2">Información del producto</h3>
            <form-wizard
              color="#7367F0"
              :title="null"
              :subtitle="null"
              layout="horizontal"
              finish-button-text="Confirmar"
              next-button-text="Siguiente"
              back-button-text="Regresar"
              class="wizard-vertical mb-3"
              shape="square"
              step-size="sm"
              @on-complete="onSubmit"
            >
              <tab-content
                title="Información del producto"
                :before-change="validateProductData"
              >
                <validation-observer ref="refProductData">
                  <validation-provider
                    #default="validationContext"
                    name="Precio"
                    rules="required|numeric"
                  >
                    <b-form-group label="Precio" label-for="precio">
                      <b-input-group>
                        <b-form-input
                          id="precio"
                          v-model="productFormData.unit_price"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="0.00"
                        />
                      </b-input-group>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="validationContext"
                    name="Cost"
                    rules="required|numeric"
                  >
                    <b-form-group label="Costo" label-for="cost">
                      <b-input-group>
                        <b-form-input
                          id="cost"
                          v-model="productFormData.unit_cost"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="0.00"
                        />
                      </b-input-group>

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <p class="mt-1 visibility">Visibilidad del producto</p>
                  <b-button-group size="sm" class="mt-50">
                    <b-button
                      :variant="
                        !productFormData.is_private
                          ? 'success'
                          : 'outline-secondary'
                      "
                      @click="productFormData.is_private = false"
                    >
                      Publico
                    </b-button>
                    <b-button
                      :variant="
                        productFormData.is_private
                          ? 'danger'
                          : 'outline-secondary'
                      "
                      @click="productFormData.is_private = true"
                    >
                      Privado
                    </b-button>
                  </b-button-group>
                </validation-observer>
              </tab-content>

              <tab-content v-if="variants.length > 0">
                <b-form-group
                  label="¿El producto o servicio tiene variantes que deseas combinar?"
                  size="lg"
                  label-for="has-variants"
                  label-size="lg"
                >
                  <b-button-group
                    id="has-variants"
                    v-model="selectedOptionForVariant"
                    class="d-flex"
                  >
                    <b-button
                      :variant="
                        selectedOptionForVariant ? 'success' : 'outline-success'
                      "
                      @click="handleVariantButton(true)"
                    >
                      Si
                    </b-button>
                    <b-button
                      :variant="
                        selectedOptionForVariant === false
                          ? 'danger'
                          : 'outline-danger'
                      "
                      @click="handleVariantButton(false)"
                    >
                      No
                    </b-button>
                  </b-button-group>
                </b-form-group>

                <!-- Variants selection -->
                <b-form-group
                  v-if="has_variants === '1'"
                  label="Variantes"
                  class="mt-2"
                >
                  <b-form-tags
                    v-model="variants_ids"
                    size="lg"
                    add-on-change
                    no-outer-focus
                  >
                    <template
                      v-slot="{
                        tags,
                        inputAttrs,
                        inputHandlers,
                        disabled,
                        removeTag,
                      }"
                    >
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="primary"
                            class="my-50 mr-25"
                            @remove="removeTag(tag), handleRemoveTag()"
                          >
                            {{ getVariantTagNameById(tag) }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        :disabled="disabled || availableVariants.length === 0"
                        :options="availableVariants"
                        text-field="name"
                        value-field="id"
                        v-on="inputHandlers"
                        @change="resetVariantOptionTags"
                      >
                        <template v-slot:first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option disabled value="">
                            Selecciona las variantes que deseas combinar...
                          </option>
                        </template>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                </b-form-group>
                <b-form-group
                  label="Opciones de variantes"
                  v-if="has_variants === '1' && variants_ids.length >= 1"
                >
                  <b-form-tags
                    v-model="variant_option_ids"
                    size="lg"
                    add-on-change
                    no-outer-focus
                  >
                    <template
                      v-slot="{
                        tags,
                        inputAttrs,
                        inputHandlers,
                        disabled,
                        removeTag,
                      }"
                    >
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="primary"
                            class="my-50 mr-25"
                            @remove="removeTag(tag)"
                          >
                            {{ getVariantOptionTagNameById(tag) }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        :disabled="
                          disabled || availableVariantOptions.length === 0
                        "
                        :options="availableVariantOptions"
                        text-field="combination_name"
                        value-field="ids"
                        v-on="inputHandlers"
                      >
                        <template v-slot:first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option disabled value="">
                            Selecciona las opciones deseadas...
                          </option>
                        </template>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                </b-form-group>
              </tab-content>

              <tab-content v-if="variant_option_ids.length > 0">
                <b-form-group
                  label="¿Deseas manejar inventario para las variantes?"
                  label-size="lg"
                >
                  <b-button-group
                    id="has-inventory"
                    v-model="selectedOptionForInventory"
                    class="d-flex"
                  >
                    <b-button
                      :variant="
                        selectedOptionForInventory
                          ? 'success'
                          : 'outline-success'
                      "
                      @click="handleInventoryButton(true)"
                      >Si</b-button
                    >
                    <b-button
                      :variant="
                        selectedOptionForInventory === false
                          ? 'danger'
                          : 'outline-danger'
                      "
                      @click="handleInventoryButton(false)"
                      >No</b-button
                    >
                  </b-button-group>
                </b-form-group>
                <div v-if="has_inventory === '1'" class="d-flex mt-2">
                  <b-col>
                    <b-row>
                      <b-col cols="12" class="mb-2">
                        <p class="mb-0 font-">
                          Ingresa el inventario para cada una de las opciones de
                          variantes
                        </p>
                      </b-col>
                    </b-row>
                    <div
                      v-for="(option, index) in variant_option_ids"
                      :key="index"
                    >
                      <b-row>
                        <b-col
                          size="3"
                          class="d-flex align-items-left"
                          cols="8"
                        >
                          <h6>
                            <b-badge pill :variant="'light-info'" class="mt-1">
                              {{ getVariantOptionTagNameById(option) }}
                            </b-badge>
                          </h6>
                        </b-col>
                        <b-col cols="4">
                          <div>
                            <b-form-input
                              :id="'variant-option-info-' + index"
                              v-model="variant_option_inventory(option).units"
                              :state="true"
                              trim
                              placeholder="15"
                              size="lg"
                              type="number"
                              @input="
                                updateVariantOptionInventory(option, $event)
                              "
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </div>
              </tab-content>

              <tab-content v-else>
                <b-form-group
                  label="¿Deseas manejar inventario para este producto o servicio?"
                  label-for="has-inventory"
                  label-size="lg"
                >
                  <b-button-group
                    id="has-inventory"
                    v-model="selectedOptionForInventory"
                    class="d-flex align-items-center"
                  >
                    <b-button
                      :variant="
                        selectedOptionForInventory
                          ? 'success'
                          : 'outline-success'
                      "
                      @click="handleInventoryButton(true)"
                    >
                      Si
                    </b-button>
                    <b-button
                      :variant="
                        selectedOptionForInventory === false
                          ? 'danger'
                          : 'outline-danger'
                      "
                      @click="handleInventoryButton(false)"
                    >
                      No
                    </b-button>
                  </b-button-group>
                </b-form-group>

                <b-form-group
                  v-if="has_inventory === '1'"
                  label="Ingresa el inventario para el producto"
                  label-for="unique-inventory"
                >
                  <b-form-input
                    id="unique-inventory"
                    v-model="units"
                    autofocus
                    :state="true"
                    trim
                    placeholder="0"
                    type="number"
                  />
                </b-form-group>
              </tab-content>

              <tab-content>
                <b-form-group
                  label="¿Deseas añadir una descripción?"
                  label-for="has-description"
                  label-size="lg"
                >
                  <b-button-group
                    id="has-description"
                    v-model="selectedOptionForDescription"
                    class="d-flex align-items-center"
                  >
                    <b-button
                      :variant="
                        selectedOptionForDescription
                          ? 'success'
                          : 'outline-success'
                      "
                      @click="handleDescriptionButton(true)"
                    >
                      Si
                    </b-button>
                    <b-button
                      :variant="
                        selectedOptionForDescription === false
                          ? 'danger'
                          : 'outline-danger'
                      "
                      @click="handleDescriptionButton(false)"
                    >
                      No
                    </b-button>
                  </b-button-group>
                </b-form-group>

                <div v-if="has_description === '1'">
                  <validation-provider
                    #default="{ errors, valid }"
                    name="ProductDescription"
                    rules="required"
                  >
                    <b-form-group
                      label="Escribe una descripción detallada..."
                      label-for="product_description"
                    >
                      <b-form-input
                        id="product_description"
                        v-model="product_description"
                        trim
                        placeholder="Este producto es para..."
                        type="text"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </div>
              </tab-content>

              <tab-content :before-change="validateBundles">
                <div>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="isBundle"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                    inline
                  >
                    <div class="d-flex flex-column">
                      <span> ¿Desea tener bundles (paquetes)? </span>
                      <small>
                        Seleccione esta opción si desea agregar bundles al
                        producto.
                      </small>
                    </div>
                  </b-form-checkbox>
                </div>

                <validation-observer v-if="isBundle" ref="refBundles">
                  <div
                    v-for="(bundle, index) in bundles"
                    class="border p-2 rounded mt-2"
                    :key="index"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Nombre"
                      rules="required"
                    >
                      <b-form-group label="Nombre del bundle">
                        <b-form-input
                          v-model="bundle.name"
                          placeholder="Paquete"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Cantidad"
                      rules="required|numeric|min_value:2"
                    >
                      <b-form-group label="Cantidad de productos del paquete">
                        <b-form-input
                          v-model="bundle.quantity"
                          type="number"
                          placeholder="2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Código QR"
                      rules="qrcode"
                    >
                      <b-form-group label="Código QR (opcional)">
                        <b-form-input
                          v-model="bundle.qr_code"
                          type="text"
                          placeholder="1234567890"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ errors }"
                      name="SKU"
                      rules="sku"
                    >
                      <b-form-group label="Código de barras (opcional)">
                        <b-form-input
                          v-model="bundle.sku"
                          type="text"
                          placeholder="ABC1234567"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      v-if="variant_option_ids.length > 0"
                      v-slot="{ errors }"
                      name="Variante"
                      rules="required"
                    >
                      <b-form-group label="Seleccione una variante">
                        <v-select
                          v-model="bundle.variant_option_id"
                          :options="
                            variant_option_ids.map((id) => ({
                              value: id,
                              label: getVariantOptionTagNameById(id),
                            }))
                          "
                          :reduce="(val) => val.value"
                          class="h-10"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>

                    <b-button
                      v-if="index > 0"
                      class="mt-2"
                      variant="outline-danger"
                      @click="removeBundle(index)"
                    >
                      Eliminar bundle
                    </b-button>
                  </div>

                  <b-button
                    class="mt-2"
                    variant="outline-secondary"
                    @click="addBundle"
                  >
                    Agregar bundle
                  </b-button>
                </validation-observer>
              </tab-content>

              <tab-content>
                <b-form-group
                  label="¿Facturas las ventas de este producto?"
                  label-for="has-tax"
                  label-size="lg"
                >
                  <b-button-group
                    id="has-tax"
                    v-model="selectedOptionForHasTax"
                    class="d-flex align-items-center"
                  >
                    <b-button
                      :variant="
                        selectedOptionForHasTax ? 'success' : 'outline-success'
                      "
                      @click="handleHasTaxButton(true)"
                      >Si</b-button
                    >
                    <b-button
                      :variant="
                        selectedOptionForHasTax === false
                          ? 'danger'
                          : 'outline-danger'
                      "
                      @click="handleHasTaxButton(false)"
                      >No</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </tab-content>

              <tab-content
                v-if="productData.product_type === 'product'"
                :before-change="validateUnitType"
              >
                <validation-observer ref="refUnitType">
                  <b-form-group label="Unidad de medida" label-for="unit-type">
                    <validation-provider
                      #default="{ errors }"
                      name="Codigo de unidad"
                      rules="required"
                    >
                      <v-select
                        v-model="unitCode"
                        :options="unitCodeOptions"
                        :reduce="(val) => val.value"
                        label="name"
                        class="h-10"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </validation-observer>
              </tab-content>
            </form-wizard>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import vSelect from "vue-select"
import { ref } from "@vue/composition-api"
import { FormWizard, TabContent } from "vue-form-wizard"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"

import store from "@/store"
import router from "@/router"
import formValidation from "@core/comp-functions/forms/form-validation"
import ProductInfoCard from "@/views/products/product-view/ProductInfoCard.vue"

import messagesMixin from "@/@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/utils/fetch-utils"

export default {
  components: {
    // Local Components
    ProductInfoCard,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,

    // Form Wizard
    FormWizard,
    TabContent,
  },
  mixins: [messagesMixin],
  setup() {
    const productData = ref(null)
    store
      .dispatch("products/fetchProduct", router.currentRoute.params.product_id)
      .then((response) => {
        productData.value = response
      })
      .catch((error) => {
        if (error.response.status === 404) {
          productData.value = undefined
        }
      })

    const blankProductData = {
      unit_cost: 0,
      unit_price: 0,
      store_id: router.currentRoute.params.id,
      product_id: router.currentRoute.params.product_id,
      has_inventory: false,
      inventory: 0,
      is_private: false,
    }

    const productFormData = ref(JSON.parse(JSON.stringify(blankProductData)))
    const resetProductData = () => {
      productFormData.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const { getValidationState } = formValidation(resetProductData)

    return {
      getValidationState,
      productData,
      productFormData,
    }
  },
  data() {
    return {
      selectedOptionForVariant: false,
      has_variants: "0",
      has_inventory: "0",
      has_description: "0",
      has_tax_response: "0",
      variants_ids: [],
      isBundle: false,
      bundles: [
        {
          name: null,
          sku: null,
          qr_code: null,
          quantity: null,
          variant_option_id: null,
        },
      ],
      step: 1,
      units: 0,
      variant_option_inventories: {},
      variant_option_ids: [],
      variant_options_combinations: [],
      entriesPerPage: 1000000,
      selectedOptionForInventory: false,
      selectedOptionForDescription: false,
      product_description: "",
      selectedOptionForHasTax: false,
      unitCode: null,
      unitCodeOptions: [
        {
          name: "Por pieza",
          value: "H87",
        },
        {
          name: "Por kilogramo",
          value: "KGM",
        },
        {
          name: "Por litro",
          value: "LTR",
        },
        {
          name: "Por gramo",
          value: "GRM",
        },
        {
          name: "Por miligramo",
          value: "MGM",
        },
        {
          name: "Por mililitro",
          value: "MLT",
        },
        {
          name: "Por tonelada de carga",
          value: "A75",
        },
      ],
    }
  },
  computed: {
    ...mapGetters("variants", ["variants", "selected_variants"]),

    availableVariants() {
      // eslint-disable-next-line
      return this.variants.filter((opt) => {
        return this.variants_ids.indexOf(opt.id) === -1
      })
    },

    availableVariantOptions() {
      return this.variant_options_combinations.filter((opt) => {
        return this.variant_option_ids.indexOf(opt.ids) === -1
      })
    },

    variant_option_inventory() {
      return (optionId) => {
        return this.variant_option_inventories[optionId] || { units: null }
      }
    },
  },
  beforeMount() {
    this.fetchVariants({
      by_active_status: "active",
      meta: {
        pagination: {
          per_page: this.entriesPerPage,
        },
      },
    })
  },
  watch: {
    variants_ids() {
      this.set_selected_variants([])
      const selectedVariants = this.variants.filter((variant) => {
        return this.variants_ids.indexOf(variant.id) !== -1
      })
      this.set_selected_variants(selectedVariants)
      this.setVariantOptions()
    },
  },

  methods: {
    ...mapActions("storeProducts", ["addStoreProduct"]),
    ...mapActions("variants", ["fetchVariants"]),
    ...mapMutations("variants", [
      "set_selected_variants",
      "delete_selected_variants",
    ]),

    addBundle() {
      this.bundles.push({
        name: null,
        sku: null,
        qr_code: null,
        quantity: null,
        variant_option_id: null,
      })
    },

    removeBundle(index) {
      this.bundles.splice(index, 1)
    },

    validateBundleQuantity(tag) {
      return !isNaN(tag)
    },

    setOptionNames() {
      let option_names = []
      this.selected_variants.forEach((variant) => {
        let options = []
        variant.variant_options_attributes.forEach((option) => {
          options.push({ id: option.id, option_name: option.option_name })
        })
        option_names.push(options)
      })
      return option_names
    },
    onSubmit() {
      if (!this.productFormData.has_inventory) {
        this.productData.inventory = 0
      }

      this.productFormData.unit_code =
        this.productData.product_type === "product" ? this.unitCode : "E48"

      const productData = this.setStoreProduct()
      
      const promises = productData.map((product) => {
        return this.addStoreProduct(product).catch((e) => {
          const errors = handleAPIErrors(e.response.data)
          this.errorToast(errors[0])
        })
      })

      Promise.all(promises).then(() => {
        this.successToast("Producto agregado")
        this.$router.push({
          name: "store-products",
          params: { id: this.$route.params.id },
        })
      })
    },
    handleHasTaxButton(value) {
      this.selectedOptionForHasTax = value
      this.has_tax_response = value ? "1" : "-1"
    },
    handleRemoveTag() {
      this.variant_option_ids = []
    },

    setVariantOptions() {
      function generateCombinations(
        arraysOfObjects,
        currentIndex = 0,
        currentCombination = "",
        currentIds = "",
        result = []
      ) {
        if (currentIndex === arraysOfObjects.length) {
          result.push({ combination_name: currentCombination, ids: currentIds })
          return
        }

        arraysOfObjects[currentIndex].forEach((option) => {
          const updatedIds = currentIds
            ? `${currentIds};${option.id}`
            : option.id // Update ids string
          generateCombinations(
            arraysOfObjects,
            currentIndex + 1,
            currentCombination.length
              ? `${currentCombination} - ${option.option_name}`
              : option.option_name,
            updatedIds,
            result
          )
        })
      }

      const result = []
      const option_names = this.setOptionNames()
      generateCombinations(option_names, 0, "", [], result)
      this.variant_options_combinations = result
    },

    setStoreProduct() {
      const baseProduct = {
        ...this.productFormData,
        description: this.product_description,
        unit_code: this.unitCode || "E48",
        has_inventory: this.has_inventory === "1",
        has_tax: this.has_tax_response === "1",
        tax_type: this.has_tax_response === "1" ? "IVA" : "",
        price_includes_tax: this.has_tax_response === "1",
      }

      if (this.variant_option_ids.length === 0) {
        return [
          {
            ...baseProduct,
            inventory: this.units,
            bundles_attributes: this.isBundle ? this.bundles : null,
          },
        ]
      } else {
        return this.variant_option_ids.map((optionId) => {
          const inventory = this.variant_option_inventories[optionId]
          const store_product_variants_attributes = optionId
            .split(";")
            .filter(Boolean)
            .map((id) => ({
              variant_option_id: id,
            }))

          const bundles = this.bundles.filter((bundle) => {
            return bundle.variant_option_id === optionId
          })

          return {
            ...baseProduct,
            bundles_attributes: this.isBundle ? bundles : null,
            inventory: inventory ? this.units : 0,
            store_product_variants_attributes,
          }
        })
      }
    },

    handleVariantButton(value) {
      this.selectedOptionForVariant = value
      this.has_variants = value ? "1" : "-1"
    },
    updateVariantOptionInventory(optionId, value) {
      this.$set(this.variant_option_inventories, optionId, { units: value })
    },

    handleInventoryButton(value) {
      this.selectedOptionForInventory = value
      this.has_inventory = value ? "1" : "-1"
    },
    resetVariantOptionTags() {
      this.variant_option_ids = []
    },
    handleDescriptionButton(value) {
      this.selectedOptionForDescription = value
      this.has_description = value ? "1" : "-1"
    },
    getVariantTagNameById(id) {
      let name = ""
      this.variants.forEach((x) => {
        if (x.id === id) {
          name = x.name
        }
      })
      return name
    },
    getVariantOptionTagNameById(id) {
      let name = ""
      this.variant_options_combinations.forEach((x) => {
        if (x.ids === id) {
          name = x.combination_name
        }
      })
      return name
    },

    // VALIDATIONS

    validateProductData() {
      return new Promise((resolve, reject) => {
        this.$refs.refProductData.validate().then((success) => {
          if (success) {
            if (
              Number(this.productFormData.unit_price) <
              Number(this.productFormData.unit_cost)
            ) {
              this.errorToast("El precio de venta debe ser mayor al costo")
              reject(false)
            }
            resolve(true)
          } else {
            this.errorToast("Por favor, llena todos los campos")
            reject(false)
          }
        })
      })
    },

    validateUnitType() {
      return new Promise((resolve, reject) => {
        this.$refs.refUnitType.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.errorToast("Por favor, selecciona una unidad de medida")
            reject(false)
          }
        })
      })
    },

    validateBundles() {
      return new Promise((resolve, reject) => {
        if (this.isBundle) {
          this.$refs.refBundles.validate().then((success) => {
            if (success) {
              const uniqueValues = new Set()
              let isValid = true

              this.bundles.forEach((bundle) => {
                if (
                  //uniqueValues.has(bundle.quantity) ||
                  uniqueValues.has(bundle.qr_code) ||
                  uniqueValues.has(bundle.sku)
                ) {
                  isValid = false
                } else {
                  //uniqueValues.add(bundle.quantity)

                  if (bundle.qr_code) uniqueValues.add(bundle.qr_code)
                  if (bundle.sku) uniqueValues.add(bundle.sku)
                }
              })

              if (!isValid) {
                this.errorToast("Código QR y SKU debe ser único")
                //this.errorToast("Cada cantidad, código QR y SKU debe ser único")
                reject(false)
              } else {
                resolve(true)
              }
              resolve(true)
            } else {
              this.errorToast("Por favor, llena todos los campos")
              reject(false)
            }
          })
        } else {
          resolve(true)
        }
      })
    },
  },
}
</script>

<style lang="scss">
.vue-form-wizard {
  .wizard-header {
    display: none;
  }

  ul.wizard-nav-pills,
  .wizard-progress-with-circle {
    display: none !important;
  }

  .wizard-tab-content {
    padding: 1rem;
  }
}
</style>

<style lang="scss" scoped>
.visibility {
  font-size: 0.9rem;
  font-weight: 500;
}
</style>
