<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === undefined">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link class="alert-link" :to="{ name: 'apps-e-commerce-shop' }">
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <div v-if="product" class="position-relative">
      <div class="starred">
        <i
          class="fa-star fa-2x"
          :class="$route.params.starred ? 'fas' : 'far'"
          @click="
            starProduct({
              store_id: $route.params.store_id,
              store_product_id: $route.params.store_product_id,
              is_favorite: $route.params.starred,
            })
          "
        />
      </div>
      <b-row class="my-2 mx-0">
        <!-- Left: Product Image Container -->
        <b-col
          cols="12"
          md="5"
          lg="4"
          class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-center w-100">
            <b-img
              :src="`${product.logo}`"
              :alt="`Image of ${product.name}`"
              class="product-img"
              rounded
              fluid
            />
          </div>
        </b-col>

        <!-- Right: Product Details -->
        <b-col cols="12" md="7" lg="8">
          <!-- Product Name -->
          <h4>{{ product.name }}</h4>

          <!-- Product Brand -->
          <b-card-text class="item-company mb-0">
            <p class="mb-0">
              {{ product.variant }}
            </p>
          </b-card-text>

          <!-- Price And Ratings -->
          <div v-if="product.is_edible" class="d-flex align-items-center my-1">
            <div class="ecommerce-details-price d-flex flex-wrap">
              <nutri-score
                v-if="product.nutritional_info && product.is_edible"
                :nutritional-info="product.nutritional_info"
              />
            </div>
          </div>

          <!-- Avability -->
          <b-card-text
            >Producto -
            <span
              :class="product.active_status ? 'text-success' : 'text-danger'"
              >{{ product.active_status ? "Activo" : "Desactivado" }}</span
            ></b-card-text
          >

          <!-- Product Description -->
          <!-- <b-card-text>{{ product.description }}</b-card-text> -->

          <hr />

          <!-- Colors -->
          <div class="product-color-option pt-0">
            <h6>Categorias</h6>
            <ul class="list-unstyled m-0">
              <li
                v-for="category in product.categories_names"
                :key="`category-${category}`"
                class="d-inline-block"
              >
                <b-badge>{{ category }}</b-badge>
              </li>
            </ul>
          </div>

          <hr />

          <div class="d-flex flex-column flex-sm-row">
            <div class="d-flex align-items-center">
              <i class="fas fa-barcode fa-2x mr-1" />
              {{ product.sku }}
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex"
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BAlert,
  BBadge,
} from "bootstrap-vue"

import NutriScore from "@/@core/components/NutriScore.vue"
import Ripple from "vue-ripple-directive"

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BAlert,
    BBadge,

    NutriScore,
  },

  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapActions("storeProducts", ["starProduct"]),
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.starred {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
</style>
